import { graphql, useStaticQuery } from 'gatsby'
import { ReactElement } from 'react'

import { ContentfulConstantsProvider } from '../context/contentful-constants'
import About from '../components/homepage/about'
import Contact from '../components/homepage/contact'
import EmailMe from '../components/homepage/email-me'
import Footer from '../components/footer'
import Hero from '../components/homepage/hero'
import Intro from '../components/homepage/intro'
import Nav from '../components/nav'
import Newsletter from '../components/newsletter'
import References from '../components/homepage/references'
import SEO from '../components/seo'
import Services from '../components/homepage/services'

const IndexPage = (): ReactElement => {
  const {
    pageMetadata: { author, description, keywords, title },
  } = useStaticQuery(graphql`
    query {
      pageMetadata: contentfulSeo(homepage: { eq: true }) {
        author
        description
        keywords
        title
      }
    }
  `)

  return (
    <ContentfulConstantsProvider>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        author={author}
      />
      <div id="zacatek" className="text-gray-800 bg-ghost-white">
        <Nav />
        <Hero />

        <main className="pb-10">
          <div className="from-white to-ghost-white bg-gradient-to-b">
            <Intro />

            <Services />
          </div>

          <About />

          <References />

          <Contact />

          <EmailMe />

          <Newsletter />
        </main>

        <Footer />
      </div>
    </ContentfulConstantsProvider>
  )
}

export default IndexPage
