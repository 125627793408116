import {
  faChalkboardTeacher,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { ReactElement, useContext } from 'react'

import { ContentfulConstantsContext } from '../../context/contentful-constants'
import Button from '../ui/button'
import Card from '../ui/card'
import Options from '../rich-text-render-options'
import Wrapper from '../ui/wrapper'

library.add(faChalkboardTeacher, faUserFriends, faUsers)

const Services = (): ReactElement => {
  const { email } = useContext(ContentfulConstantsContext)
  const {
    data: { services },
  } = useStaticQuery(graphql`
    query {
      data: contentfulServices(node_locale: { eq: "cs-CZ" }) {
        services {
          title
          icon
          description {
            raw
          }
        }
      }
    }
  `)

  return (
    <div id="sluzby">
      <Wrapper>
        <section className="py-10 lg:grid grid-cols-3 gap-4 space-y-10 lg:space-y-0">
          {services?.map(({ title, icon, description }: any) => (
            <Card
              className="p-4 flex flex-col justify-between"
              key={encodeURIComponent(title)}
            >
              <div className="space-y-4">
                <div className="text-3xl text-dark-cornflower-blue text-center">
                  <FontAwesomeIcon icon={icon} />
                </div>
                <h1 className="text-4xl font-medium text-center text-dark-cornflower-blue">
                  {title}
                </h1>
                <div className="space-y-4">
                  {renderRichText(description, Options)}
                </div>
              </div>
              <div className="mt-8 text-center">
                <Button href={`mailto:${email}`} variant="primary">
                  Napište mi
                </Button>
              </div>
            </Card>
          ))}
        </section>
      </Wrapper>
    </div>
  )
}

export default Services
