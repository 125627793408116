import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { FC } from 'react'
import { v4 } from 'uuid'

import Card from '../ui/card'
import Wrapper from '../ui/wrapper'

const References: FC = () => {
  const {
    data: { nodes: clients },
  } = useStaticQuery(graphql`
    query {
      data: allContentfulCustomers(filter: { node_locale: { eq: "cs-CZ" } }) {
        nodes {
          logo {
            gatsbyImageData(height: 150, placeholder: TRACED_SVG)
          }
          clientDisplayName
        }
      }
    }
  `)

  return (
    <section id="reference" className="py-20 bg-cadet-grey">
      <Wrapper>
        <div
          className="mt-4 sm:grid gap-4 space-y-4 sm:space-y-0"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gridAutoRows: '1fr',
          }}
        >
          {clients.map(
            ({
              clientDisplayName,
              logo,
            }: {
              clientDisplayName: string
              logo: IGatsbyImageData
            }) => {
              return (
                <Card key={clientDisplayName || v4()} className="flex flex-col">
                  <div className="flex-grow flex items-center justify-center p-4">
                    <GatsbyImage
                      image={getImage(logo) as IGatsbyImageData}
                      alt={clientDisplayName}
                      objectFit="contain"
                    />
                  </div>
                  {clientDisplayName && (
                    <div className="bg-ghost-white p-4 text-sm text-center">
                      {clientDisplayName}
                    </div>
                  )}
                </Card>
              )
            }
          )}
        </div>
      </Wrapper>
    </section>
  )
}

export default References
