import { graphql, useStaticQuery } from 'gatsby'
import { FC, createContext } from 'react'

type ContentfulConstants = {
  email?: string
  phone?: string
}

export const ContentfulConstantsContext = createContext<ContentfulConstants>({})

export const ContentfulConstantsProvider: FC = ({ children }) => {
  const {
    data: { nodes: constants },
  } = useStaticQuery(graphql`
    query {
      data: allContentfulTextConstants(
        filter: { node_locale: { eq: "cs-CZ" } }
      ) {
        nodes {
          constantName
          constantValue
        }
      }
    }
  `)

  const email = constants.find(
    ({ constantName }: any) => constantName === 'CONTACT_EMAIL'
  ).constantValue

  const phone = constants.find(
    ({ constantName }: any) => constantName === 'CONTACT_PHONE'
  ).constantValue

  return (
    <ContentfulConstantsContext.Provider
      value={{
        email,
        phone,
      }}
    >
      {children}
    </ContentfulConstantsContext.Provider>
  )
}
