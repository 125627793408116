import { FC } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import StyledProps from '../../types/styled-props'

interface Props {
  variant?: 'primary' | 'secondary' | 'ghost'
  inverted?: boolean
  type?: 'button' | 'submit'
  disabled?: boolean
  href?: string
  to?: string
  onClick?: () => void
}

const Button: FC<Props & StyledProps> = ({
  variant = 'primary',
  inverted = false,
  type = 'button',
  disabled = false,
  children,
  href,
  to,
  onClick,
  className,
}) => {
  const css = classNames(
    'inline-block py-3 px-6 rounded-md font-medium text-center transition-all',
    {
      'bg-frostbite hover:bg-opacity-90': variant === 'primary',
      'bg-cadet-grey hover:bg-opacity-90': variant === 'secondary',
      'hover:bg-blue-munsell hover:bg-opacity-5': variant === 'ghost',
      'text-white':
        (variant === 'primary' && !inverted) ||
        (variant === 'ghost' && inverted),
      'text-gray-800': variant === 'ghost' && !inverted,
      'cursor-not-allowed bg-opacity-5 hover:bg-opacity-5': disabled,
    },
    className
  )

  if (href) {
    return (
      <a href={href} className={css}>
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={to} className={css}>
        {children}
      </Link>
    )
  }

  return (
    <button type={type} disabled={disabled} onClick={onClick} className={css}>
      {children}
    </button>
  )
}

export default Button
