import { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Wrapper from '../ui/wrapper'

const Hero = (): ReactElement => (
  <div className="bg-dark-cornflower-blue">
    <Wrapper>
      <header className="relative lg:flex flex-row items-center justify-around overflow-hidden py-20 lg:py-0">
        <div className="flex-shrink-0">
          <div className="px-4">
            <h1 className="text-6xl text-white font-semibold">Petr Hrůza</h1>
            <h2 className="text-3xl text-ghost-white font-medium whitespace-nowrap">
              antifraud boomer
            </h2>
          </div>
        </div>
        <div className="hidden lg:block text-center w-1/2 flex-grow-0">
          <StaticImage
            src="../../images/hero.svg"
            alt="Logo Petr Hrůza"
            placeholder="tracedSVG"
          />
        </div>
      </header>
    </Wrapper>
  </div>
)

export default Hero
