import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { useMedia, useToggle } from 'react-use'

import Button from '../ui/button'
import Wrapper from '../ui/wrapper'
import Items from './items'

const Nav: FC = () => {
  const isSmGreater = useMedia('(min-width: 640px)')
  const [isHamburgerOpen, toggleHamburger] = useToggle(false)

  return (
    <nav className="sticky z-10 top-0">
      <Wrapper
        outerClassName="bg-ghost-white shadow-lg ring-1 ring-gray-100"
        className="flex justify-between"
      >
        <a href="#zacatek" className="w-5/12 sm:w-3/12 flex items-center">
          <StaticImage
            src="../../images/logo-black.png"
            alt="Logo Petr Hrůza"
            placeholder="tracedSVG"
          />
        </a>

        {isSmGreater && <Items />}

        {!isSmGreater && (
          <Button
            variant="ghost"
            className="text-2xl font-semibold"
            onClick={() => toggleHamburger()}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        )}
      </Wrapper>

      {!isSmGreater && isHamburgerOpen && (
        <div className="absolute right-0 bg-ghost-white">
          <Items className="flex-col items-stretch p-4 bg-ghost-white shadow-lg ring-1 ring-gray-100" />
        </div>
      )}
    </nav>
  )
}

export default Nav
