import { StaticImage } from 'gatsby-plugin-image'
import { ReactElement, useContext } from 'react'

import { ContentfulConstantsContext } from '../../context/contentful-constants'

import Wrapper from '../ui/wrapper'

const Contact = (): ReactElement => {
  const { email } = useContext(ContentfulConstantsContext)

  return (
    <section id="kontakt" className="py-10">
      <Wrapper>
        <div className="px-4 lg:flex flex-row items-center justify-around">
          <div className="text-center">
            <div className="space-y-4">
              <h1 className="text-5xl text-dark-cornflower-blue">Kontakt</h1>
              <h2 className="text-xl">Petr Hrůza</h2>
              <a
                href={`mailto:${email}`}
                className="inline-block text-xl underline hover:no-underline"
              >
                {email}
              </a>
              <p className="text-sm">Bankovní spojení: 300469652/0300</p>
              <p className="text-base">
                IČ 11819430
              </p>
              <p className="text-sm">
                  Zapsaný u Městského úřadu v&nbsp;Brandýse nad Labem
              </p>
              <p className="text-sm">Nejsem plátce DPH</p>
            </div>
          </div>
          <div className="hidden lg:block text-center">
            <StaticImage
              src="../../images/contact.svg"
              alt="Ilustrační obrázek kontaktů"
              aria-hidden="true"
              placeholder="tracedSVG"
              className="w-9/12"
            />
          </div>
        </div>
      </Wrapper>
    </section>
  )
}

export default Contact
