import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

import Wrapper from './ui/wrapper'

const Footer: FC = () => (
  <footer className="py-10 text-xs border-t border-gray-300 text-gray-600">
    <Wrapper>
      <div className="sm:grid grid-cols-3">
        <div className="flex items-center whitespace-nowrap">
          &copy; {new Date().getFullYear()} Petr Hrůza
        </div>
        <div className="flex items-center justify-center space-x-4 text-xl">
          <a
            href="https://www.facebook.com/antifraudboomer"
            target="_blank"
            rel="noreferrer noopener"
            className="hover:text-gray-800 transition-colors"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.linkedin.com/in/petrhruza"
            target="_blank"
            rel="noreferrer noopener"
            className="hover:text-gray-800 transition-colors"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://www.instagram.com/ph_hruzic"
            target="_blank"
            rel="noreferrer noopener"
            className="hover:text-gray-800 transition-colors"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </Wrapper>
  </footer>
)

export default Footer
