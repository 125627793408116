import classNames from 'classnames'
import { FC } from 'react'

import Button from '../ui/button'
import StyledProps from '../../types/styled-props'

const Items: FC<StyledProps> = ({ className }) => {
  const css = classNames('flex items-center', className)

  return (
    <ul className={css}>
      <li>
        <Button variant="ghost" href="#sluzby" className="w-full">
          Služby
        </Button>
      </li>
      <li>
        <Button variant="ghost" href="#o-me" className="w-full">
          O mně
        </Button>
      </li>
      <li>
        <Button variant="ghost" href="#reference" className="w-full">
          Reference
        </Button>
      </li>
      <li>
        <Button variant="ghost" href="#kontakt" className="w-full">
          Kontakt
        </Button>
      </li>
    </ul>
  )
}

export default Items
