import classNames from 'classnames'
import {
  createRef,
  FormEventHandler,
  ReactElement,
  useContext,
  useState,
} from 'react'
import Recaptcha from 'react-google-recaptcha'

import { ContentfulConstantsContext } from '../context/contentful-constants'
import Button from './ui/button'
import Card from './ui/card'
import Wrapper from './ui/wrapper'

const RECAPTCHA_KEY =
  process.env.GATSBY_SITE_RECAPTCHA_KEY || process.env.SITE_RECAPTCHA_KEY || ''

const Newsletter = (): ReactElement => {
  const [isFilled, setFilled] = useState(false)
  const [targetEmail, setTargetEmail] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const { email } = useContext(ContentfulConstantsContext)

  const inputCss = classNames('px-2 py-2 leading-relaxed rounded-md border ', {
    'border-gray-400': !isSubmitting,
    'text-gray-600 border-gray-200 bg-gray-50': isSubmitting,
  })

  const recaptchaRef = createRef<Recaptcha>()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    setSubmitting(true)

    const recaptchaValue = (await recaptchaRef?.current?.executeAsync()) || ''

    const data = {
      'form-name': 'newsletter',
      'g-recaptcha-response': recaptchaValue,
      targetEmail,
    }

    const keys = Object.keys(data) as (keyof typeof data)[]
    const body = keys
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
      })

      setFilled(true)
    } catch (e) {
      alert(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Wrapper>
      <section className="py-10">
        <Card className="px-4 py-8">
          <div className="md:flex flex-row items-center justify-between">
            <h1 className="text-5xl text-dark-cornflower-blue">Newsletter</h1>
            {isFilled && (
              <div className="mt-4 md:mt-0 px-2 py-1 rounded-md text-white bg-blue-munsell">
                E-mail uložen. Můžete se těšit!
              </div>
            )}
            {!isFilled && (
              <>
                <form
                  name="newsletter"
                  method="post"
                  className="mt-4 md:mt-0"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="grid gap-2 md:grid-cols-2">
                    <input
                      type="email"
                      name="email"
                      placeholder="váš e-mail"
                      required
                      className={inputCss}
                      readOnly={isSubmitting}
                      onChange={({ target: { value } }) =>
                        setTargetEmail(value)
                      }
                    />
                    <Button
                      type="submit"
                      variant="secondary"
                      disabled={isSubmitting}
                    >
                      Přihlásit se
                    </Button>
                  </div>
                  <Recaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    size="invisible"
                  />
                </form>
              </>
            )}
          </div>
          <p className="mt-4 text-sm">
            Newsletter budu zasílat jednou měsíčně.
          </p>
        </Card>
        <p className="mt-2 px-4 text-xs">
          Odhlášení můžete provést pomocí odkazu v&nbsp;newsletteru, nebo{' '}
          <a
            href={`mailto:${email}?subject=Zrušení odběru newsletteru&body=Nemám již zájem`}
            className="underline hover:no-underline"
          >
            zasláním e-mailu
          </a>
          .
        </p>
      </section>
    </Wrapper>
  )
}

export default Newsletter
