import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { FC } from 'react'

import Options from '../rich-text-render-options'
import Wrapper from '../ui/wrapper'

const About: FC = () => {
  const {
    texts: { aboutMe },
  } = useStaticQuery(graphql`
    query {
      texts: contentfulHomepage {
        aboutMe {
          raw
        }
      }
    }
  `)

  return (
    <section id="o-me" className="py-10">
      <Wrapper>
        <div className="px-4 lg:flex flex-row items-center justify-between">
          <div>
            <h1 className="text-5xl text-dark-cornflower-blue whitespace-nowrap">
              O mně
            </h1>
            <div className="mt-6 space-y-4">
              {renderRichText(aboutMe, Options)}
            </div>
          </div>
          <div className="hidden lg:block flex-shrink-0 text-center">
            <StaticImage
              src="../../images/about.svg"
              alt="Ilustrační obrázek představení"
              aria-hidden="true"
              placeholder="tracedSVG"
              className="w-9/12"
            />
          </div>
        </div>
      </Wrapper>
    </section>
  )
}

export default About
