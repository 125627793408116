import { FC } from 'react'
import classNames from 'classnames'

import StyledProps from '../../types/styled-props'

interface Props {
  bg?: string
  outerClassName?: string
}

const Wrapper: FC<Props & StyledProps> = ({
  children,
  className,
  bg,
  outerClassName,
}) => {
  const css = classNames(
    'mx-auto max-w-screen-xl px-4 xl:px-0',
    { 'py-4': !!bg },
    className
  )

  const outerCss = classNames(outerClassName, bg)

  return (
    <div className={outerCss}>
      <div className={css}>{children}</div>
    </div>
  )
}

export default Wrapper
