import classNames from 'classnames'
import { forwardRef, HTMLProps } from 'react'

import StyledProps from '../../types/styled-props'

const Card = forwardRef<
  HTMLDivElement,
  StyledProps & HTMLProps<HTMLDivElement>
>(({ children, className }, ref) => {
  const css = classNames(
    'rounded-lg overflow-hidden bg-white shadow-sm',
    className
  )

  return (
    <div className={css} ref={ref}>
      {children}
    </div>
  )
})

Card.displayName = 'Card'

export default Card
