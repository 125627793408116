/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import { ReactNode } from 'react'

/* eslint-disable react/display-name */
const Options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }: any, children: ReactNode) => {
      const comparedUrl = data.uri.indexOf(process.env.GATSBY_SITE_URL)
      return (
        <>
          {comparedUrl > -1 ? (
            <Link to={data.uri} className="underline hover:no-underline">
              {children}
            </Link>
          ) : (
            <a href={data.uri} className="underline hover:no-underline">
              {children}
            </a>
          )}
        </>
      )
    },
    [BLOCKS.PARAGRAPH]: (_: any, children: ReactNode) => <p>{children}</p>,
  },
}

export default Options
