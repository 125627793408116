import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { FC } from 'react'

import Options from '../rich-text-render-options'
import Wrapper from '../ui/wrapper'

const Intro: FC = () => {
  const {
    texts: { intro },
  } = useStaticQuery(graphql`
    query {
      texts: contentfulHomepage {
        intro {
          raw
        }
      }
    }
  `)

  return (
    <Wrapper>
      <section className="px-4 pt-20 pb-10">
        {renderRichText(intro, Options)}
      </section>
    </Wrapper>
  )
}

export default Intro
