import { ReactElement, useContext } from 'react'

import { ContentfulConstantsContext } from '../../context/contentful-constants'
import Button from '../ui/button'

const EmailMe = (): ReactElement => {
  const { email } = useContext(ContentfulConstantsContext)

  return (
    <section className="py-10 text-center">
      <Button href={`mailto:${email}`}>Napište mi</Button>
    </section>
  )
}

export default EmailMe
